import React, { useEffect, useRef } from "react";
import { NebulaStyled } from "./NebulaStyled";

export const Nebula = ({ nebula, setParallax }) => {
  const nebulaRef = useRef();
  useEffect(() => {
    nebulaRef && setParallax(nebulaRef, 0, 0, nebula.parallax, 1);
  }, [nebulaRef, nebula, setParallax]);
  return (
    <NebulaStyled ref={nebulaRef} src={nebula.img} zindex={nebula.zIndex} />
  );
};
