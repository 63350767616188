import React, { useState, useEffect } from "react";
import { SpriteConfigSecond } from "./config";
import { useDispatch, useSelector } from "react-redux";

import { ZoomWrapper } from "../ZoomStyled";
import { AnimatedSprite } from "../AnimatedSprite";

//Import Actions
import { SelectSector, CloseNovaModal } from "../../../redux/actions";

//Import Component
import { MachineTelemetry } from "../../MachineTelemetry";
import { MachineLog } from "../../MachineLog";
import { NovaPhoto } from "../../NovaPhoto";
import { ChartModal } from "../../Charts";

export const ScreenThird = ({ setParallax }) => {
  const [novaFixed, setNovaFixed] = useState(false);
  const currentNFT = useSelector((state) => state?.env.currentNFT);
  const NFTDetails = useSelector((state) => state?.nft);
  const NFTSingleID = useSelector((state) => state?.nft?.superNovaDetails?.supernovaId);
  const dispatch = useDispatch();

  const handleMouseEvent = ({ event = "" }) => {
    if (event === "OVER") {
      dispatch(SelectSector({ isVisible: true }));
    } else {
      dispatch(SelectSector({ isVisible: false }));
    }
  };

  return (
    <ZoomWrapper>
      {NFTDetails?.activeModal === "machineLogVisible" && (
        <MachineLog
          manufcture={NFTDetails.superNovaDetails?.manufactured}
          installed={NFTDetails.superNovaDetails?.installed}
          novaId={NFTDetails.superNovaDetails?.supernovaId}
          onClose={() =>
            dispatch(CloseNovaModal({ value: "machineLogVisible" }))
          }
        />
      )}
      {NFTDetails?.activeModal === "machineTelemetryVisible" && (
        <MachineTelemetry
          novaId={NFTDetails.superNovaDetails?.supernovaId}
          onClose={() =>
            dispatch(CloseNovaModal({ value: "machineTelemetryVisible" }))
          }
        />
      )}
      {NFTDetails?.activeModal === "novaPhotoVisible" && (
        <NovaPhoto
          novaPhoto={NFTDetails.superNovaDetails?.photo}
          onClose={() =>
            dispatch(CloseNovaModal({ value: "novaPhotoVisible" }))
          }
        />
      )}
      {NFTDetails?.activeModal === "novaChartVisible" && (
        <ChartModal
          NFTSingleID={NFTSingleID}
          onClose={() =>
            dispatch(CloseNovaModal({ value: "novaChartVisible" }))
          }
        />
      )}
      {SpriteConfigSecond.map((sprite, index) => {
        return (
          <AnimatedSprite
            key={sprite.sprite_url + "_" + index}
            sprite={sprite}
            sprite_url={currentNFT}
            setParallax={setParallax}
            onMouseOver={() => handleMouseEvent({ event: "OVER" })}
            onMouseLeave={() =>
              !novaFixed && handleMouseEvent({ event: "LEAVE" })
            }
            onClick={() => setNovaFixed(true)}
          />
        );
      })}
    </ZoomWrapper>
  );
};
