import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectorsConfig } from "./config";

//Import Actions
import {
  TransitionNavigation,
  SelectSector,
  SetSuperNovaDetails,
} from "../../../redux/actions";

//Background star imports
import { ZoomWrapper } from "../ZoomStyled";
import { AnimatedSprite } from "../AnimatedSprite";

export const ScreenSecond = React.memo(({ setParallax }) => {
  const current_sector_id = useSelector((state) => state?.nft.current_sector);
  const dispatch = useDispatch();

  const filterData = SectorsConfig.filter(
    (item) => +item.sector_id === +current_sector_id
  );

  const handleClick = ({ zoom, sprite_url, supernova_detail }) => {
    dispatch(TransitionNavigation(zoom, sprite_url));
    dispatch(SelectSector({ isVisible: false }));
    dispatch(SetSuperNovaDetails({ supernova_detail }));
  };

  const handleMouseEvent = ({ event = "", supernova_detail = {} }) => {
    // console.log(filterData);
    if (event === "OVER") {
      dispatch(SetSuperNovaDetails({ supernova_detail }));
      dispatch(SelectSector({ isVisible: true }));
    } else {
      dispatch(SelectSector({ isVisible: false }));
    }
  };

  return (
    <ZoomWrapper>
      {filterData.length > 0 &&
        filterData[0].nftList?.map((sprite, index) => {
          return (
            <AnimatedSprite
              key={sprite.sprite_url + "_" + index}
              sprite={sprite}
              sprite_url={sprite.sprite_url}
              setParallax={setParallax}
              onClick={() =>
                handleClick({
                  zoom: 3,
                  sprite_url: sprite.sprite_url,
                  supernova_detail: sprite?.supernovaDetails,
                })
              }
              onMouseOver={() =>
                handleMouseEvent({
                  event: "OVER",
                  supernova_detail: sprite?.supernovaDetails,
                })
              }
              onMouseLeave={() => handleMouseEvent({ event: "LEAVE" })}
            />
          );
        })}
    </ZoomWrapper>
  );
});
