import React from "react";

//Import Styles
import {
  TableWrapper,
  TableItem,
  Count,
  Name,
  MachinesInfo,
  TableLabel,
  TableDivider,
  ScrolbleWrapper,
} from "./MachinesUsedStyled";

//Import Mock data
import { ACTIVE_SUPERNOVAS } from "../../api/NebulaServices/config";

export const MachinesUsedTable = ({ isLoading, supernovas = [] }) =>
  React.useMemo(() => {
    return (
      <TableWrapper>
        <TableItem key={"header_key#0"} thead={true}>
          <Count>
            <TableLabel bold={true}>#</TableLabel>
          </Count>
          <Name>
            <TableLabel bold={true}>Name</TableLabel>
          </Name>
          <MachinesInfo>
            <TableLabel bold={true}>Status</TableLabel>
          </MachinesInfo>
          <MachinesInfo>
            <TableLabel bold={true}>Last Price</TableLabel>
          </MachinesInfo>
          <MachinesInfo>
            <TableLabel bold={true}>Sessions*</TableLabel>
          </MachinesInfo>
        </TableItem>
        <ScrolbleWrapper>
          {!isLoading &&
            supernovas?.length > 0 &&
            supernovas?.map((item, index) => (
              <>
                <TableItem key={index}>
                  <Count>
                    <TableLabel>{index + 1}</TableLabel>
                  </Count>
                  <Name>
                    <TableLabel>{
                      ACTIVE_SUPERNOVAS.filter(
                        (el) => el.id === item.string_id
                      )[0].name}
                    </TableLabel>
                  </Name>
                  <MachinesInfo>
                    <TableLabel>{item?.actual_status}</TableLabel>
                  </MachinesInfo>
                  <MachinesInfo>
                    <TableLabel>
                      {
                        ACTIVE_SUPERNOVAS.filter(
                          (el) => el.id === item.string_id
                        )[0].price
                      } eth
                    </TableLabel>
                  </MachinesInfo>
                  <MachinesInfo>
                    <TableLabel>{item?.user_sessions}</TableLabel>
                  </MachinesInfo>
                </TableItem>
                <TableDivider />
              </>
            ))}
        </ScrolbleWrapper>
      </TableWrapper>
    );
  });
