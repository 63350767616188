import styled, { keyframes } from "styled-components";

import background from "./../../assets/transitionScene/test.png";

const move = keyframes`
    0%{
        transform: translateZ(-500px) rotate(0deg);
}
    100%{
        transform: translateZ(500px) rotate(0deg);
}
`;

const fade = keyframes`
    0%{
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`;

export const TransitionStyled = styled.div`
  display: inline-block;
  perspective: 2px;
  perspective-origin: 50% 50%;
  top: 50%;
  left: 50%;
  transition: 0.7s ease;
  pointer-events: none;
  opacity: ${({ active }) => (active ? "1" : "0")};
  position: fixed;
  z-index: 99;
`;
export const TransitionWrap = styled.div`
  position: absolute;
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  transform-style: preserve-3d;
  animation: ${move} 20s infinite linear;
  animation-fill-mode: forwards;
  &:nth-child(2) {
    animation: ${move} 10s infinite linear;
    .wall {
      animation-delay: 10s;
    }
  }
`;
export const Wall = styled.div`
  background: url(${background});
  background-color: #000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: ${({ active }) => active && fade} 4s infinite linear;
  animation-delay: 0s;
  transform: ${({ position }) =>
    position === "left"
      ? "rotateY(-90deg) translateZ(500px)"
      : position === "right"
      ? "rotateY(90deg) translateZ(500px)"
      : position === "top"
      ? "rotateX(90deg) translateZ(500px)"
      : position === "bottom"
      ? "rotateX(-90deg) translateZ(500px)"
      : "rotateX(180deg) translateZ(500px)"};
`;
