import styled from "styled-components";

export const NavigationWrapper = styled.div`
  position: relative;
  z-index: ${(props) => props.zindex};
`;

export const NavigationSidebar = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  width: 300px;
  height: 100vh;
  z-index: 13;
  padding: 0 20px 0 0;
`;
