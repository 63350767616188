import starsPic from "../../../assets/zoom_first/bgstars/stars_layer1.png";
import starsPic2 from "../../../assets/zoom_first/bgstars/stars_layer2.png";
import starsPic3 from "../../../assets/zoom_first/bgstars/stars_layer3.png";

export const StarsConfig = [
  {
    img: starsPic,
    parallax: { x: 1, y: 0.5 },
    zIndex: 1,
  },
  {
    img: starsPic2,
    parallax: { x: 1.5, y: 1 },
    zIndex: 2,
  },
  {
    img: starsPic3,
    parallax: { x: 2, y: 1.5 },
    zIndex: 3,
  },
];
