import { ServiceCall } from "../index";

//Import routes
import { routes } from "../routes";

//Import mock data
import { ACTIVE_SUPERNOVAS } from "./config";

export const GetNebulaStatistics = async () => {
  const res = await ServiceCall({ route: routes.nebula.statistics });
  if (res.statusCode === 200) {
    return res?.data?.active_supernovas;
  } else {
    return null;
  }
};

export const GetNebulaActives = async (nftID = false) => {
  const res = await ServiceCall({
    route: routes.nebula.active,
    params: {
      nebulas: nftID ? [nftID] : ACTIVE_SUPERNOVAS.map((item) => item.id),
    },
    type: "POST",
  });
  if (res.statusCode === 200) {
    return res?.data;
  } else {
    return null;
  }
};
