import {
  AnimatedSpriteStyled,
  UseStartNotification,
  AnimatedSpriteWrapper,
  SpriteControl,
} from "./AnimatedSpriteStyled";
import { useEffect, useRef, useState } from "react";
import { useProgressiveImage } from "../../../hooks/UseProgressiveImage";
import { SPRITES_CONFIG } from "../../../config";

export const AnimatedSprite = ({
  sprite,
  setParallax,
  sprite_url,
  onClick,
  onMouseLeave,
  onMouseOver,
}) => {
  const ref = useRef();
  const spriteImage = useProgressiveImage(sprite.sprite_url);
  const [loadedImages, setLoadedImages] = useState(false);
  useEffect(() => {
    setLoadedImages(spriteImage);
  }, [spriteImage]);

  useEffect(() => {
    !!loadedImages &&
      ref.current?.style &&
      setParallax(
        ref,
        sprite.position.x,
        sprite.position.y,
        sprite.parallax,
        (SPRITES_CONFIG.spriteW * sprite.scale) / 8
      );
  }, [ref, setParallax, sprite, loadedImages]);

  return !!loadedImages ? (
    <AnimatedSpriteWrapper
      position={sprite.position}
      size={sprite.size}
      scale={sprite.scale}
      ref={ref}
      onMouseLeave={onMouseLeave}
    >
      <AnimatedSpriteStyled
        size={sprite.size}
        scale={sprite.scale_sprite || sprite.scale}
        spriteSheet={sprite_url}
      >
        {sprite.isUsed && (
          <UseStartNotification position={sprite.position} size={sprite.size}>
            {/* 1 used now */}
          </UseStartNotification>
        )}
      </AnimatedSpriteStyled>
      <SpriteControl
        size={sprite.size}
        scale={sprite.scale}
        onClick={onClick}
        onMouseOver={onMouseOver}
      />
    </AnimatedSpriteWrapper>
  ) : null;
};
