import styled, { keyframes } from "styled-components";

export const SlideUp = keyframes`
  0% { 
    transform: translate(-50%, 70%); 
    z-index: 0
  }
  100% { 
    transform: translate(-50%, -50%);
  }
`;

const ChartModalWrapper = styled.div`
  position: absolute;
  max-width: 700px;
  width: 700px;
  height: 544px;
  background: rgba(0,0,0,0.352941);
  border: 1px solid #464646;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 43px 27px 10px 27px;
  top: 50%;
  left: 50%;
  z-index: 66;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: ${SlideUp} 0.6s linear;
  animation-delay: ${(props) => props.delay};
`;

const ModalTitle = styled.span`
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

const IDMachine = styled.span`
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 15px;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: #ffdd50;
  margin-top: 5px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const BottomLabel = styled.span`
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  padding: 30px 0 20px 0;
  align-items: center;
  word-spacing: 6px;
`;

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export {
  ChartModalWrapper,
  ModalTitle,
  Divider,
  CloseIcon,
  IDMachine,
  BottomWrapper,
  BottomLabel,
};
