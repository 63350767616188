import React, { useState, useEffect } from "react";

export const TelemetrySVG = ({ svgPath = null }) => {
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    svgPath &&
      fetch(svgPath)
        .then((res) => res.text())
        .then((text) => setSvg(text));
  }, []);

  if (svg) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    );
  }
  return <></>;
};
