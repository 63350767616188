import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"

//Import styled components

import { SvgSliderWrapper, SvgSlider } from './NavigationSliderStyled';

//Import Actions 
import { TransitionNavigation } from "../../redux/actions"

export const NavigationSlider = () => {

  const dispatch = useDispatch()
  const current_zoom = useSelector(state => state?.env?.zoom)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [svgSize, setSvgSize] = useState({
    width: 650,
    height: 75
  })

  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  };

  const handleActive = (item) => {
    if (+item?.slot === current_zoom) {
      item.classList.add("activeNavigationPoint");
    } else {
      item.classList.remove("activeNavigationPoint");
    }
  }

  const handleBackPoint = (item) => {
    if (+item?.slot === current_zoom - 1) {
      item.classList.add("navigationBackPoint");
    } else {
      item.classList.remove("navigationBackPoint");
    }
  }

  useEffect(() => {
    window.addEventListener('resize', getWindowWidth);

    windowWidth <= 767 && setSvgSize({ width: 450, height: 35 })
    windowWidth <= 575 && setSvgSize({ width: 350, height: 20 })
    windowWidth <= 479 && setSvgSize({ width: 270, height: 30 })
    windowWidth > 767 && setSvgSize({ width: 650, height: 70 })

  }, [windowWidth])

  useEffect(() => {
    const nodeList = document.querySelectorAll("#navigations .zoom");
    nodeList.forEach((item) => {
      handleActive(item)
      handleBackPoint(item)
      item.onclick = () => {
        if (+item?.slot === current_zoom - 1) {
          dispatch(TransitionNavigation(+item?.slot));
        }
      };
      item.onmouseover = () => {
        handleActive(item)
      };
      item.onmouseleave = () => {
        handleActive(item)
      };
    });
  });

  return (
    <SvgSliderWrapper>
      <SvgSlider width={svgSize.width} height={svgSize.height} id="navigations">
        <g stroke="lightgrey">
          <path fill="transparent" stroke="#FFDD50" strokeWidth="1" d={` 
              M0,${svgSize.height}
              Q${svgSize.width / 2},-${svgSize.height} ${svgSize.width},${svgSize.height} 
            `} />
        </g>
        <circle slot="1" className="zoom" cx="0" cy={svgSize.height} r="11" />
        <circle slot="2" className="zoom" cx={svgSize.width / 2} cy="0" r="11" />
        <circle slot="3" className="zoom" cx={svgSize.width} cy={svgSize.height} r="11" />
      </SvgSlider>
    </SvgSliderWrapper>
  )
};