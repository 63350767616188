import React, { useMemo } from "react";
import { Nebula } from "../nebula";
import { SpriteConfigFirst } from "./config";
import { ZoomWrapper } from "../ZoomStyled";
import { NebulaConfig } from "../nebula/config";
import { AnimatedSprite } from "../AnimatedSprite";
import { NebulaWrapper, NebulaContent } from "../nebula/NebulaStyled";
import { useDispatch, useSelector } from "react-redux";
import { TransitionNavigation } from "../../../redux/actions";

//Import components
import { Svg } from "../../SectorsSVG";
import SECTORS from "../../../assets/sectors.svg";

export const ScreenFirst = ({ setParallax }) => {
  const dispatch = useDispatch();

  const nebulaList = useMemo(
    () =>
      NebulaConfig.map((nebula, index) => (
        <Nebula
          key={nebula.zIndex + index}
          setParallax={setParallax}
          nebula={nebula}
        />
      )),
    [NebulaConfig]
  );

  return (
    <ZoomWrapper>
      <NebulaContent>
        <NebulaWrapper>
          <Svg svgPath={SECTORS} />
          {nebulaList}
        </NebulaWrapper>
      </NebulaContent>
      {SpriteConfigFirst.map((sprite, index) => {
        return (
          <AnimatedSprite
            key={sprite.sprite_url + "_" + index}
            sprite={sprite}
            sprite_url={sprite.sprite_url}
            setParallax={setParallax}
            onClick={() => dispatch(TransitionNavigation(2))}
          />
        );
      })}
    </ZoomWrapper>
  );
};
