import React from "react";
import { TransitionStyled, TransitionWrap, Wall } from "./TransitionStyled";

export const Transition = ({ active }) => {
  return (
    <TransitionStyled active={active}>
      <TransitionWrap>
        <Wall position={"right"} active={active} />
        <Wall position={"left"} active={active} />
        <Wall position={"top"} active={active} />
        <Wall position={"bottom"} active={active} />
        <Wall position={"back"} active={active} />
      </TransitionWrap>
      <TransitionWrap>
        <Wall position={"right"} active={active} />
        <Wall position={"left"} active={active} />
        <Wall position={"top"} active={active} />
        <Wall position={"bottom"} active={active} />
        <Wall position={"back"} active={active} />
      </TransitionWrap>
    </TransitionStyled>
  );
};
