import styled, { keyframes } from "styled-components";

export const SlideUp = keyframes`
  0% { 
    transform: translate(-50%, 70%); 
    z-index: 0
  }
  100% { 
    transform: translate(-50%, -50%);
  }
`;

const ChartModalWrapper = styled.div`
  position: absolute;
  max-width: 700px;
  width: 700px;
  background: rgba(0,0,0,0.352941);
  // height: 540px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 43px 27px 10px 27px;
  top: 50%;
  left: 50%;
  z-index: 66;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: ${SlideUp} 0.6s linear;
  animation-delay: ${(props) => props.delay};
`;

const ModalTitle = styled.span`
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: #ffdd50;
  margin-top: 5px;
`;

const CustomToolTipWrapper = styled.div`
  background: #00000034 0% 0% no-repeat padding-box;
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 18px;
  flex-direction: column;
`;

const ToolTipLabel = styled.span`
  font-size: 14px;
  color: #fff;
  letter-spacing: 0px;
  font-weight: 400;
  align-items: center;
`;

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

const ScrolbleWrapper = styled.div`
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export {
  ChartModalWrapper,
  ModalTitle,
  Divider,
  CustomToolTipWrapper,
  ToolTipLabel,
  CloseIcon,
  ScrolbleWrapper,
};
