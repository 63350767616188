import { NFT_TYPES } from "../../types";

const initialState = {
  current_sector: null,
  supernovasOnline: 0,
  superNovaDetails: {},
  activeModal: "",
};

export const NftReducer = (state = initialState, action) => {
  switch (action.type) {
    case NFT_TYPES.SET_SELECTED_SECTOR:
      return {
        ...state,
        current_sector: action.payload,
      };
    case NFT_TYPES.SET_ONLINE_SUPERNOVAS:
      return {
        ...state,
        supernovasOnline: action.payload,
      };
    case NFT_TYPES.SET_NOVA_DETAILS:
      return {
        ...state,
        superNovaDetails: action.payload,
      };

    case NFT_TYPES.OPEN_NOVA_MODAL:
      return {
        ...state,
        activeModal: action.payload,
      };

    case NFT_TYPES.CLOSE_NOVA_MODAL:
      return {
        ...state,
        activeModal: "",
      };

    default:
      return state;
  }
};
