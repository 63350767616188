export const NOTIFICATION = [
  {
    id: 1,
    name: "1 usage at Atlanta",
    usage: 1,
  },
  {
    id: 2,
    name: "1 usage at New York",
    usage: 1,
  },
  {
    id: 3,
    name: "1 usage at Florida",
    usage: 1,
  },
  {
    id: 41,
    name: "1 usage at Seattle",
    usage: 1,
  },
  {
    id: 15,
    name: "1 usage at Califirnia La Habra",
    usage: 1,
  },
  {
    id: 31,
    name: "1 usage at Colorado Lakewood",
    usage: 1,
  },
  {
    id: 144,
    name: "1 usage at Hawaii Mililani Town",
    usage: 1,
  },
];
