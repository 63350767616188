import styled from "styled-components";

export const StarsStyled = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  height: 100%;
  z-index: ${(props) => props.zindex};
`;
