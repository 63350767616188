import React, { useState, useEffect } from "react";
import {
  NotificationWrapper,
  NotificationInfo,
  NotificationCount,
  NotificationCountInfo,
  NotificationWrapperVisible,
} from "./NotificationStyled";

//MOCK DATA for notification
import { NOTIFICATION } from "../NavigationProvider/mockNotification";

export const Notification = () => {
  const dismissTime = 3500;
  const [toastList, setToastList] = useState([]);
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      toastList.length > 0 && deleteToast(toastList[0].id);
    }, dismissTime);
    return () => {
      clearInterval(interval);
    };
  }, [list]);

  useEffect(() => {
    const interval = setInterval(() => {
      toastList.length < 3 &&
        addToast(
          NOTIFICATION[Math.floor(Math.random() * NOTIFICATION.length - 1) + 1]
        );
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  const addToast = (notificationObject) => {
    !toastList.map((el) => el.id).includes(notificationObject.id) &&
      setToastList([...toastList, notificationObject]);
  };

  return (
    <NotificationWrapperVisible>
      {list.length > 0 &&
        list?.map((item, index) => (
          <NotificationWrapper key={item?.id}>
            <NotificationCount>
              <NotificationCountInfo>
                +{item?.usage || "0"}
              </NotificationCountInfo>
            </NotificationCount>
            <NotificationInfo>{item?.name || "No data"}</NotificationInfo>
          </NotificationWrapper>
        ))}{" "}
    </NotificationWrapperVisible>
  );
};
