import styled from "styled-components";

const StarsOnlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const StarsOnline = styled.span`
  color: #ffdd50;
  letter-spacing: 0px;
  color: #fff;
  text-transform: uppercase;
  font-family: Quantico, sans-serif;
  font-size: 29px;
`;

const StarsDescr = styled.span`
  font-family: Montserrat;
  color: #ffdd50;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-size: 12px;
`;

export { StarsOnline, StarsOnlineWrapper, StarsDescr };
