import styled, { keyframes } from "styled-components";

export const SlideUp = keyframes`
  0% { 
    transform: translate(-50%, 70%); 
    z-index: 0
  }
  100% { 
    transform: translate(-50%, -50%);
  }
`;

const ChartModalWrapper = styled.div`
  position: absolute;
  max-width: 700px;
  width: 700px;
  height: 544px;
  background: rgba(0,0,0,0.352941) 0% 0% no-repeat padding-box;
  border: 1px solid #464646;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 43px 27px 10px 27px;
  top: 50%;
  left: 50%;
  z-index: 66;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: ${SlideUp} 0.6s linear;
  animation-delay: ${(props) => props.delay};
`;

const ModalTitle = styled.span`
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

const IDMachine = styled.span`
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 15px;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: #ffdd50;
  margin-top: 5px;
`;

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

const CircleTime = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #fff;
  content: " ";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const CheckIcon = styled.img`
  width: 60%;
  height: 65%;
`;

const SingleTimelineWrapper = styled.div`
  display: flex;
  max-width: 358px;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  padding-left: 35px;
  margin-bottom: 5px;
  min-height: 70px;
`;

const DecorCirlce = styled.div`
  position: absolute;
  left: 50%;
  top: 30px;
  height: calc(100% - 30px);
  width: 2px;
  display: block;
  background: #808080;
  transform: translateX(-50%);
  display: ${(props) => (props.last ? "none" : "block")};
`;

const Label = styled.div`
  width: 40%;
  position: relative;
  color: #fff;
  font-size: 15px;
`;

const LabelTitle = styled.div`
  width: 50%;
  position: relative;
  color: #fff;
  font-size: 15px;
`;

const LabelDescr = styled.div`
  width: 60%;
  position: relative;
  color: #fff;
  font-size: 15px;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

const EditorInfo = styled.div`
  width: 60%;
  padding: 15px 35px;
  border: 2px solid #808080;
`;

const LabelDivider = styled.span`
  display: inline-flex;
  width: 3px;
  font-size: 15px;
  color: #ffdd50;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  margin-right: 10px;
`;

const TimelineDecor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 25px;
`;

export {
  ChartModalWrapper,
  ModalTitle,
  Divider,
  CloseIcon,
  IDMachine,
  CircleTime,
  CheckIcon,
  SingleTimelineWrapper,
  DecorCirlce,
  Label,
  LabelWrapper,
  EditorInfo,
  TimelineDecor,
  LabelTitle,
  LabelDescr,
  LabelDivider
};
