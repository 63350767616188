import anime from "animejs/lib/anime.es.js";
import { useEffect } from "react";
import {
  TwinklingFallingStar,
  TwinklingStar,
  TwinklingStarsWrapper,
  TwinklingWish,
} from "./TwinklingStarsStyled";

export const TwinklingStars = ({ quantity }) => {
  const vw = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  const starryNight = () => {
    anime({
      targets: [".sky .star"],
      opacity: [
        {
          duration: 700,
          value: "0",
        },
        {
          duration: 700,
          value: "1",
        },
      ],
      easing: "linear",
      loop: true,
      delay: (el, i) => 50 * i,
    });
  };
  const shootingStars = () => {
    anime({
      targets: [".shootingstars .wish"],
      easing: "linear",
      loop: true,
      delay: (el, i) => 3000 * i,
      opacity: [
        {
          duration: 700,
          value: "1",
        },
      ],
      width: [
        {
          value: "150px",
        },
        {
          value: "0px",
        },
      ],
      translateX: 350,
    });
  };
  const randomRadius = () => {
    return Math.random() * 0.7 + 0.6;
  };
  const getRandomX = () => {
    return Math.floor(Math.random() * Math.floor(vw)).toString();
  };
  const getRandomY = () => {
    return Math.floor(Math.random() * Math.floor(vh)).toString();
  };
  useEffect(() => {
    starryNight();
    shootingStars();
  }, []);
  return (
    <TwinklingStarsWrapper>
      <TwinklingStar className={"sky"}>
        {[...Array(quantity)].map((x, y) => (
          <circle
            cx={getRandomX()}
            cy={getRandomY()}
            r={randomRadius()}
            stroke="none"
            strokeWidth="0"
            className={"star"}
            fill="white"
            key={y}
          />
        ))}
      </TwinklingStar>
      <TwinklingFallingStar className={"shootingstars"}>
        {[...Array(quantity)].map((x, y) => (
          <TwinklingWish
            className={"wish"}
            key={y}
            style={{
              left: `${getRandomY()}px`,
              top: `${getRandomX()}px`,
            }}
          />
        ))}
      </TwinklingFallingStar>
    </TwinklingStarsWrapper>
  );
};
