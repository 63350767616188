import React from "react";
import { useDispatch } from "react-redux";

//Improt Cluster styles
import {
  ClusterHeaderTitle,
  ClusterDivider,
  ClusterContent,
  SuperNovaContent,
  ClusterDescription,
  SuperNovaLabel,
  SuperNovaInfo,
  LabelDivider,
  ClusterClickable,
  ClusterWarning,
  SuperNonaLink,
} from "../ClusterStyled";

//Import Action
import { OpenNovaModal } from "../../../redux/actions";

const NovaItem = ({ label = "", value = "" }) => {
  return (
    <SuperNovaContent>
      <SuperNovaLabel>
        <ClusterDescription>{label || ""}</ClusterDescription>
      </SuperNovaLabel>
      <SuperNovaInfo>
        <LabelDivider>:</LabelDivider>
        <ClusterDescription>{value || ""}</ClusterDescription>
      </SuperNovaInfo>
    </SuperNovaContent>
  );
};

const NovaItemLink = ({ label = "", href = "", target = "_blank" }) => {
  return (
    <SuperNonaLink href={href} target={target} >{label}</SuperNonaLink>
  )
}

export const SuperNovaDetail = ({ supernovaDetail }) => {
  const dispatch = useDispatch();
  return React.useMemo(() => {
    return (
      <>
        <ClusterHeaderTitle>STAR INFO</ClusterHeaderTitle>
        <ClusterDivider />
        <ClusterContent>
          <NovaItem label="Name" value={supernovaDetail?.name} />
          <NovaItem label="Status" value={supernovaDetail?.status} />
          <NovaItem label="Location name" value={supernovaDetail?.country} />
          <NovaItem label="Location" value={supernovaDetail?.location} />
          <NovaItem
            label="Photo"
            value={
              <ClusterClickable
                onClick={() =>
                  dispatch(OpenNovaModal({ value: "novaPhotoVisible" }))
                }
              >
                View
              </ClusterClickable>
            }
          />
          <NovaItem
            label="Usage"
            value={<ClusterWarning children={supernovaDetail?.usage} />}
          />
          <NovaItem label="Venue" value={supernovaDetail?.venue} />
          <NovaItem label="Address" value="...." />
          <NovaItem label="Installed" value={supernovaDetail?.installed} />
          <NovaItem label="Machine ID" value={supernovaDetail?.supernovaId} />
          <ClusterDescription>
            <ClusterClickable
              onClick={() =>
                dispatch(OpenNovaModal({ value: "novaChartVisible" }))
              }
            >
              Usage chart
            </ClusterClickable>
          </ClusterDescription>
          <ClusterDescription>
            <ClusterClickable
              onClick={() =>
                dispatch(OpenNovaModal({ value: "machineLogVisible" }))
              }
            >
              Manufacturing and instalation log
            </ClusterClickable>
          </ClusterDescription>
          <ClusterDescription>
            <ClusterClickable
              onClick={() =>
                dispatch(OpenNovaModal({ value: "machineTelemetryVisible" }))
              }
            >
              Live Monitor machine telemetry
            </ClusterClickable>
          </ClusterDescription>
          <ClusterDivider />
          <NovaItem
            label="NFT last price"
            value={
              <ClusterWarning
                children={`${supernovaDetail?.price || ""} ETH`}
              />
            }
          />
          <NovaItemLink
            label="OpenSea"
            href={supernovaDetail?.opeanSeaLink}
            target="_blank"
          />
        </ClusterContent>
      </>
    );
  }, [supernovaDetail]);
};
