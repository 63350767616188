import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//Import actions
import {
  SelectSector,
  TransitionNavigation,
  SetSelectedSector,
} from "../../redux/actions";

export const Svg = ({ svgPath = null }) => {
  const [svg, setSvg] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    svgPath &&
      fetch(svgPath)
        .then((res) => res.text())
        .then((text) => setSvg(text));
  }, []);

  useEffect(() => {
    const nodeList = document.querySelectorAll("#sectors g path");
    nodeList.forEach((item) => {
      item.onclick = () => {
        dispatch(SetSelectedSector({ sector_id: item.id }));
        dispatch(TransitionNavigation(2));
        dispatch(SelectSector({ isVisible: false }));
      };
      item.onmouseover = () => {
        dispatch(SelectSector({ isVisible: true }));
        dispatch(SetSelectedSector({ sector_id: item.id }));
        item.classList.add("activeSectors");
      };
      item.onmouseleave = () => {
        dispatch(SelectSector({ isVisible: false }));
        item.classList.remove("activeSectors");
      };
    });
  });

  if (svg) {
    return (
      <div
        className="area-wrapper"
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    );
  }
  return <></>;
};
