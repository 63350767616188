import styled from "styled-components";

export const AppStyled = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Button = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 16px;
  right: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  transition: all 0.2s;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  outline: none;
  background: transparent;
  z-index: 55;
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;
