import React from "react";

//Improt Cluster styles
import {
  ClusterHeaderTitle,
  ClusterDivider,
  ClusterContent,
  ClusterItem,
  ClusterTitle,
  ClusterDescription,
  ClusterLeft,
  ClusterRight,
  ClusterDescriptionUsage,
} from "../ClusterStyled";

export const ClusterDetail = ({ data }) => {
  return (
    <>
      <ClusterHeaderTitle>Cluster info</ClusterHeaderTitle>
      <ClusterDivider />
      <ClusterContent>
        {data.length > 0 ? (
          data.map((el) => (
            <ClusterItem key={el.id}>
              <ClusterLeft>
                <ClusterTitle>Star #{el.star_name.split("#")[1]}</ClusterTitle>
              </ClusterLeft>
              <ClusterRight>
                <ClusterDescription>
                  Name : {el?.star_name || ""}
                </ClusterDescription>
                <ClusterDescription>
                  Location : {el?.location || ""}
                </ClusterDescription>
                <ClusterDescription>
                  Usage :{" "}
                  <ClusterDescriptionUsage>
                    {el?.usage || "0"}
                  </ClusterDescriptionUsage>
                </ClusterDescription>
              </ClusterRight>
            </ClusterItem>
          ))
        ) : (
          <ClusterDescription>Empty Cluster...</ClusterDescription>
        )}
      </ClusterContent>
    </>
  );
};
