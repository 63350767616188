import styled from "styled-components";

const ClusterWrapper = styled.div`
  max-height: 57vh;
  height: auto;
  border: 1px solid #7070703b;
  background: rgb(70, 70, 70, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  margin: 20px 0 0 0;
  padding: 17px 20px;
  overflow: hidden;
  z-index: 13;
  font-family: Montserrat;
  transition: all 0.2s;
  transform: translateX(${({ isActive }) => (isActive ? "0" : "400px")});
`;

const ClusterDivider = styled.div`
  width: 100%;
  background: #ffdd50;
  height: 1.5px;
  margin: 8px 0 19px 0;
`;

const ClusterHeaderTitle = styled.div`
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.3px;
  text-transform: uppercase;
`;

const ClusterContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuperNovaContent = styled.div`
  display: flex;
`;

const ClusterItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

const ClusterTitle = styled.span`
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.3px;
  font-family: Montserrat;
`;

const ClusterLeft = styled.div`
  width: 30%;
`;

const ClusterRight = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
`;

const SuperNovaLabel = styled.div`
  width: 40%;
  display: flex;
`;

const SuperNovaInfo = styled.div`
  width: 60%;
  display: flex;
`;

const LabelDivider = styled.span`
  display: inline-flex;
  width: 3px;
  font-size: 15px;
  color: #ffdd50;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  margin-right: 10px;
`;

const ClusterDescription = styled.span`
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.3px;
  margin-bottom: 6px;
  font-family: Montserrat;
  text-transform: capitalize;
`;

const SuperNonaLink = styled.a`
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.3px;
  margin-bottom: 6px;
  font-family: Montserrat;
`;

const ClusterWarning = styled.span`
  font-size: 12px;
  color: #ffdd50;
  font-weight: 400;
  letter-spacing: -0.3px;
  margin-bottom: 6px;
  font-family: Montserrat;
`;

const ClusterClickable = styled.span`
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.3px;
  margin-bottom: 6px;
  font-family: Montserrat;
  text-decoration: underline;
  cursor: pointer;
`;

const ClusterDescriptionUsage = styled.span`
  font-size: 15px;
  color: #ffdd50;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  font-family: Montserrat;
`;

export {
  ClusterWrapper,
  ClusterDivider,
  ClusterHeaderTitle,
  ClusterContent,
  ClusterItem,
  ClusterTitle,
  ClusterDescription,
  ClusterLeft,
  ClusterRight,
  ClusterDescriptionUsage,
  SuperNovaInfo,
  SuperNovaLabel,
  SuperNovaContent,
  LabelDivider,
  ClusterClickable,
  ClusterWarning,
  SuperNonaLink,
};
