export const ACTIVE_SUPERNOVAS = [
    {
        name: "Supernova #1",
        id: "SC3C3738BA",
        price: "0,0825"
    },
    {
        name: "Supernova #2",
        id: "SC293C8E16",
        price: "0,085"
    },
    {
        name: "Supernova #3",
        id: "SCA9F5C0BB",
        price: "0,11"
    },
    {
        name: "Supernova #4",
        id: "SC1533E07F",
        price: "0,046"
    },
    {
        name: "Supernova #5",
        id: "SCD2FFE2E2",
        price: "0,05"
    },
    {
        name: "Supernova #6",
        id: "SCA82DA53B",
        price: "0,04"
    },
    {
        name: "Supernova #7",
        id: "SC668D8480",
        price: "0,05"
    },
]