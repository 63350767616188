import styled from "styled-components";
import { StarsStyled } from "../star/StarsStyled";

export const NebulaWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  z-index: 2;
`;

export const NebulaStyled = styled(StarsStyled)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const NebulaContent = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 1520px;
  width: 100%;
  transform: translateX(-50%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`