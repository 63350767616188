import styled from "styled-components";

const SvgSliderWrapper = styled.div`
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`
const SvgSlider = styled.svg`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;
`

export {
    SvgSliderWrapper,
    SvgSlider
};