import React from "react";

//Import styles component
import {
  CustomToolTipWrapper,
  ToolTipLabel,
} from "../ChartStyled";

export const CustomTooltip = ({ active, payload }) =>
  React.useMemo(() => {
    if (active && payload && payload.length) {
      return (
        <CustomToolTipWrapper>
          <ToolTipLabel>{payload[0].payload.name}</ToolTipLabel>
          <ToolTipLabel>Usage: {payload[0].payload.sessions}</ToolTipLabel>
        </CustomToolTipWrapper>
      );
    }

    return null;
  });
