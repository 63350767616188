import React from "react";

import CLOSE_ICON from "../../assets/close.png";

//Import styles component
import { ChartModalWrapper, CloseIcon, TestImage } from "./NovaPhotoStyled";

export const NovaPhoto = ({ onClose, novaPhoto }) => {
  return (
    <ChartModalWrapper>
      <CloseIcon onClick={onClose} src={CLOSE_ICON} />
      <TestImage src={novaPhoto} />
    </ChartModalWrapper>
  );
};
