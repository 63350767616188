import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//Improt Cluster styles
import { ClusterWrapper } from "./ClusterStyled";

//Import Component
import { ClusterDetail, SuperNovaDetail } from "./component";

//Import Mock Data
import { NFT_DETAILS_SECTORS } from "./NFTDetailsSectorsInfo.mock";

export const Cluster = () => {
  const [filterData, setFilterData] = useState([]);
  const [isLoad, setIsLoad] = useState(false)
  const isSelectedSection = useSelector( (state) => state?.env?.isSelectedEntity);
  const currentZoom = useSelector((state) => state?.env?.zoom);
  const current_sector = useSelector((state) => state?.nft?.current_sector);
  const supernovaDetail = useSelector((state) => state?.nft?.superNovaDetails);
  
  useEffect(() => {
    const filterDetailsNft = NFT_DETAILS_SECTORS.filter(
      (item) => +item?.sector_id === +current_sector
    );
    if (filterDetailsNft.length > 0) {
      setFilterData(filterDetailsNft[0].nftList);
    } else {
      setFilterData([]);
    }
  }, [current_sector]);

  return (
    <ClusterWrapper isActive={(isSelectedSection && current_sector)}>
      {currentZoom === 1 && <ClusterDetail data={filterData} />}
      {currentZoom !== 1 && (
        <SuperNovaDetail supernovaDetail={supernovaDetail} />
      )}
    </ClusterWrapper>
  );
};
