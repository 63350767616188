import styled, { keyframes } from "styled-components";

const notificationSprite = () => keyframes`
    from {transform: translateY(100%);}
    to {transform: translateY(0)}
`;

const NotificationWrapper = styled.div`
  padding: 12px 24px;
  border: 1px solid #7070703b;  
  background: rgb(70, 70, 70, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  color: #fff;
  border: 1px solid #464646;
  border-radius: 5px;
  cursor: pointer;
  z-index: 13;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-bottom: 10px;
  height: 60px;
  animation: ${notificationSprite()} 1s;
`;

const NotificationWrapperVisible = styled.div`
  display: flex;
  height: 30vh;
  max-height: 196px;
  flex-direction: column;
  overflow: hidden;
`;

const NotificationInfo = styled.span`
  color: #fff;
  letter-spacing: -0.28px;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
`;

const NotificationCount = styled.div`
  min-width: 25px;
  min-height: 25px;
  border-radius: 50px;
  background: #ffdd50;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationCountInfo = styled.span`
  color: #000000;
  font-family: Montserrat;
  letter-spacing: 0px;
  font-size: 15px;
`;

export {
  NotificationWrapper,
  NotificationInfo,
  NotificationCount,
  NotificationCountInfo,
  NotificationWrapperVisible,
};
