import React, { useState } from "react";

//Import Component
import { NavigationWrapper, NavigationSidebar } from "./NavigationStyled";
import { ConnectToMM } from "../ConnectToMM/ConnectToMM";
import { Notification } from "../Notification";
import { Cluster } from "../Cluster";
import { NavigationBottomComponent } from "../NavigationBottom";
import { ChartModal } from "../Charts";

export const NavigationProvider = ({ children }) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  return (
    <NavigationWrapper>
      {isVisibleModal && (
        <ChartModal onClose={() => setIsVisibleModal(false)} />
      )}
      <NavigationBottomComponent
        onChartOpen={() => setIsVisibleModal(true)}
        onSearchOpen={() => setIsVisibleModal(false)}
      />
      {children}
      <NavigationSidebar>
        <Notification />
        <Cluster />
      </NavigationSidebar>
      <ConnectToMM onClick={() => alert("Connected")}>
        CONNECT WALLET
      </ConnectToMM>
    </NavigationWrapper>
  );
};
