import { EnvTypes } from "../../types";

const initialState = {
  zoom: 1,
  transition: false,
  isSelectedEntity: true,
  currentSectors: null,
  currentNFT: null,
};

export const EnvReducer = (state = initialState, action) => {
  switch (action.type) {
    case EnvTypes.SET_ZOOM:
      return {
        ...state,
        zoom: action.payload.zoom,
        currentNFT: action.payload.selectedNFT,
      };
    case EnvTypes.SET_TRANSITION_NAVIGATION:
      return {
        ...state,
        transition: action.payload,
      };
    case EnvTypes.SET_CURRENT_SECTION:
      return {
        ...state,
        currentSectors: action.payload,
      };
    case EnvTypes.SET_VISIBLE_SECTION:
      return {
        ...state,
        isSelectedEntity: action.payload,
      };
    default:
      return state;
  }
};
