import styled from "styled-components";

const NavigationBottom = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  display: flex;
  height: 0;
  padding-bottom: 1577px;
  // padding-bottom: 100%;
  width: 1577px;
  // width: 100vw;
  border-radius: 50%;
  z-index: 0;
  border: 1px solid #7070703b;
  background: rgb(70, 70, 70, 0.4);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  @media (max-width: 479px){
    width: 700px;
    padding-bottom: 700px;
  }
`;

const NovaName = styled.span`
  z-index: 44;
  color: #ffdd50;
  letter-spacing: 0px;
  color: #fff;
  text-transform: uppercase;
  font-family: Quantico, sans-serif;
  font-size: 20px;
`;

const NavigationBottomWrappaer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 650px;
  width: 100%;
  z-index: 55;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 10px 20px;
`;

const MMUrl = styled.span`
  color: #ffdd50;
  letter-spacing: 0px;
  color: #ffdd50;
  text-transform: uppercase;
  font-size: 13px;
`;

export { NavigationBottom, NavigationBottomWrappaer, NovaName };
