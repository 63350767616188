import styled from "styled-components";

const ConnectToMMWrapper = styled.div`
  padding: 12px 24px;
  color: #fff;
  border: 1px solid #464646;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 13;
  border: 1px solid #7070703b;
  background: rgb(70, 70, 70, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
`;

const MMUrl = styled.span`
  color: #ffdd50;
  letter-spacing: 0px;
  color: #ffdd50;
  text-transform: uppercase;
  font-size: 13px;
  font-family: Montserrat;
`;

export { ConnectToMMWrapper, MMUrl };
