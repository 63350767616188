import React from "react";

//Import assets
import CHECK_ICON from "../../../assets/check.png";

//Impost Style
import { CircleTime, CheckIcon } from "../MachineLogStyled";

export const TimeLineCircle = ({ isChecked = true }) => {
  return <CircleTime>{isChecked && <CheckIcon src={CHECK_ICON} />}</CircleTime>;
};
