import React, { memo, useEffect, useRef } from "react";
import { StarsStyled } from "./StarsStyled";

export const Star = memo(({ star, setParallax }) => {
  const starRef = useRef();
  useEffect(() => {
    starRef && setParallax(starRef, 0, 0, star.parallax, 1);
  }, [starRef, setParallax, star]);
  return <StarsStyled ref={starRef} src={star.img} zindex={star.zIndex} />;
});
