import blue12 from "./../../../assets/zoom_first/stars/small-star_2_12x12_blue_v3.png";
import pink12 from "./../../../assets/zoom_first/stars/small-star_2_12x12_pink_v3.png";
import blue24 from "./../../../assets/zoom_first/stars/small-star_3_24x24_blue_v3.png";
import pink24 from "./../../../assets/zoom_first/stars/small-star_3_24x24_pink_v3.png";
import blue48 from "./../../../assets/zoom_first/stars/small-star_4_48x48_blue_v3.png";
import pink48 from "./../../../assets/zoom_first/stars/small-star_4_48x48_pink_v3.png";
import blue96 from "./../../../assets/zoom_first/stars/small-star_5_96x96_blue_v3.png";
import pink96 from "./../../../assets/zoom_first/stars/small-star_5_96x96_pink_v3.png";
import { isMobile } from "react-device-detect";

export const SpriteConfigFirst = [
  {
    sprite_url: blue12,
    layer_size: 128,
    position: {
      x: 67.7,
      y: 77.2,
    },
    size: 12,
    scale: 2,
    parallax: { x: 2, y: 1 },
  },
  {
    sprite_url: pink12,
    layer_size: 128,
    position: {
      x: 52.6,
      y: 41,
    },
    size: 12,
    scale: 3,
    parallax: { x: 2, y: 1 },
  },
  {
    sprite_url: pink12,
    position: {
      x: 32,
      y: 21.6,
    },
    size: 12,
    scale: 3,
    parallax: { x: 2, y: 1 },
  },
  {
    sprite_url: blue12,
    position: {
      x: 52.6,
      y: 55.6,
    },
    size: 12,
    scale: 3,
    parallax: { x: 2, y: 1 },
  },
  {
    sprite_url: pink12,
    layer_size: 256,
    position: {
      x: 86.45,
      y: 26,
    },
    size: 12,
    scale: 3,
    parallax: { x: 2, y: 1 },
  },
  {
    sprite_url: blue24,
    layer_size: 128,
    position: {
      x: 76,
      y: 44.4,
    },
    size: 24,
    scale: 6,
    parallax: { x: 3, y: 2 },
  },
  {
    sprite_url: pink24,
    layer_size: 128,
    position: {
      x: 22,
      y: 84,
    },
    size: 24,
    scale: 6,
    parallax: { x: 3, y: 2 },
  },
  {
    sprite_url: pink48,
    layer_size: 256,
    position: {
      x: 37,
      y: 95,
    },
    size: 48,
    scale: 12,
    parallax: { x: 4, y: 3 },
  },
  {
    sprite_url: blue48,
    layer_size: 128,
    position: {
      x: 56.25,
      y: 2.3,
    },
    size: 48,
    scale: 12,
    parallax: { x: 4, y: 3 },
  },
  {
    sprite_url: pink48,
    layer_size: 128,
    position: {
      x: 83.85,
      y: 21.6,
    },
    size: 48,
    scale: 12,
    parallax: { x: 4, y: 3 },
  },
  {
    sprite_url: blue48,
    layer_size: 256,
    position: {
      x: 9,
      y: 21.6,
    },
    size: 48,
    scale: 12,
    parallax: { x: 4, y: 3 },
  },
  {
    sprite_url: blue96,
    layer_size: 256,
    position: {
      x: 63,
      y: 80,
    },
    size: 96,
    scale: 24,
    parallax: { x: 5, y: 4 },
  },
  {
    sprite_url: blue96,
    position: {
      x: 24,
      y: 50,
    },
    size: 96,
    scale: 24,
    parallax: { x: 5, y: 4 },
  },
  {
    sprite_url: blue96,
    position: {
      x: 30,
      y: 78.4,
    },
    size: 96,
    scale: 24,
    parallax: { x: 5, y: 4 },
  },
  {
    sprite_url: pink96,
    position: {
      x: 47.4,
      y: 16,
    },
    size: 96,
    scale: 24,
    parallax: { x: 5, y: 4 },
    isUsed: true,
  },
];
