export const NFT_DETAILS_SECTORS = [
  {
    sector_id: 1,
    nftList: [
      {
        id: 1,
        star_name: "Supernova #1",
        location: "USA / Los Angeles",
        usage: "168",
      },
      {
        id: 14,
        star_name: "Supernova #3",
        location: "USA / Tucson",
        usage: "254",
      },
      {
        id: 131,
        star_name: "Supernova #6",
        location: "USA / Fullerton",
        usage: "51",
      },
      {
        id: 451,
        star_name: "Supernova #7",
        location: "USA / Los Angeles",
        usage: "43",
      },
    ],
  },
  {
    sector_id: 2,
    nftList: [
      {
        id: 1,
        star_name: "Supernova #2",
        location: "USA / Santa Barbara",
        usage: "110",
      },
      {
        id: 2,
        star_name: "Supernova #4",
        location: "USA / Boulder",
        usage: "189",
      },
      {
        id: 14,
        star_name: "Supernova #5",
        location: "USA / Milwaukee",
        usage: "312",
      },
    ],
  },
];
