import { NFT_TYPES } from "../../types";

const SetSelectedSector = ({ sector_id = null }) => {
  if (sector_id) {
    return {
      type: NFT_TYPES.SET_SELECTED_SECTOR,
      payload: sector_id,
    };
  }
};

const SetOnlineSupernovas = ({ supernovas = [] }) => {
  return {
    type: NFT_TYPES.SET_ONLINE_SUPERNOVAS,
    payload: supernovas,
  };
};

const SetSuperNovaDetails = ({ supernova_detail = {} }) => {
  return {
    type: NFT_TYPES.SET_NOVA_DETAILS,
    payload: supernova_detail,
  };
};

const OpenNovaModal = ({ value }) => {
  return {
    type: NFT_TYPES.OPEN_NOVA_MODAL,
    payload: value,
  };
};

const CloseNovaModal = ({ value }) => {
  return {
    type: NFT_TYPES.CLOSE_NOVA_MODAL,
    payload: value,
  };
};

export {
  SetSelectedSector,
  SetOnlineSupernovas,
  SetSuperNovaDetails,
  OpenNovaModal,
  CloseNovaModal,
};
