import supernova1 from "./../../../assets/sprites/0166.png";
import supernova2 from "./../../../assets/sprites/0042.png";
import supernova3 from "./../../../assets/sprites/0255.png";
import supernova4 from "./../../../assets/sprites/0364.png";
import supernova5 from "./../../../assets/sprites/0332.png";
import supernova6 from "./../../../assets/sprites/0242.png";
import supernova7 from "./../../../assets/sprites/0128.png";
import Nova1Photo from "./../../../assets/photosNova/Supernova1.jpeg";
import Nova2Photo from "./../../../assets/photosNova/Supernova2.jpeg";
import Nova3Photo from "./../../../assets/photosNova/Supernova3.jpeg";
import Nova4Photo from "./../../../assets/photosNova/Supernova4.jpeg";
import Nova5Photo from "./../../../assets/photosNova/Supernova5.jpeg";
import Nova6Photo from "./../../../assets/photosNova/Supernova6.jpeg";
import Nova7Photo from "./../../../assets/photosNova/Supernova7.jpeg";

import { isMobile } from "react-device-detect";

export const SectorsConfig = [
  {
    sector_id: 1,
    nftList: [
      {
        sprite_url: supernova1,
        position: {
          x: 40,
          y: 60,
        },
        size: 512,
        scale: !isMobile ? 128 : 22,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 2, y: 4 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641627061335883777",
          usage: "168",
          name: "Supernova #1",
          supernovaId: "SC3C3738BA",
          status: "active",
          location: "California State University Long Beach",
          venue: "Library Floor 1",
          installed: "January 2020",
          country: "USA / Los Angeles",
          price: "0.0825",
          manufactured: "December 2019",
          installed: "January 2020",
          photo: Nova1Photo,
        },
      },
      {
        sprite_url: supernova2,
        position: {
          x: 19,
          y: 26,
        },
        size: 512,
        scale: !isMobile ? 128 : 19,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 4, y: 3 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641628160847511553",
          usage: "110",
          name: "Supernova #2",
          supernovaId: "SC293C8E16",
          status: "active",
          location: "University of California Santa Barbara",
          venue: "Rotunda Food Court Entrance",
          installed: "February 2020",
          country: "USA / Santa Barbara",
          price: "0.085",
          manufactured: "December 2019",
          installed: "February 2020",
          photo: Nova2Photo,
        },
      },
      {
        sprite_url: supernova3,
        position: {
          x: 76,
          y: 26,
        },
        size: 512,
        scale: !isMobile ? 128 : 16,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 3, y: 2 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641629260359139329",
          usage: "254",
          name: "Supernova #3",
          supernovaId: "SCA9F5C0BB",
          status: "active",
          location: "Univercity of Arizona",
          venue: "Student Union Memorial Bldg.",
          installed: "February 2020",
          country: "USA / Tucson",
          price: "0.11",
          manufactured: "December 2019",
          installed: "February 2020",
          photo: Nova3Photo,
        },
      },
      {
        sprite_url: supernova4,
        position: {
          x: 70,
          y: 66,
        },
        size: 512,
        scale: !isMobile ? 128 : 16,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 4, y: 5 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641630359870767105",
          usage: "189",
          name: "Supernova #4",
          supernovaId: "SC1533E07F",
          status: "active",
          location: "University of Colorado Boulder",
          venue: "CUBS West Stairs",
          installed: "February 2020",
          country: "USA / Boulder",
          price: "0.046",
          manufactured: "December 2019",
          installed: "February 2020",
          photo: Nova4Photo,
        },
      },
    ],
  },
  {
    sector_id: 2,
    nftList: [
      {
        sprite_url: supernova5,
        position: {
          x: 76,
          y: 23,
        },
        size: 512,
        scale: !isMobile ? 128 : 12,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 2, y: 1 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641631459382394881",
          usage: "312",
          name: "Supernova #5",
          supernovaId: "SCD2FFE2E2",
          status: "active",
          location: "University of Wisconsin - Milwaukee",
          venue: "Student Union",
          installed: "March 2020",
          country: "USA / Milwaukee",
          price: "0.05",
          manufactured: "December 2019",
          installed: "March 2020",
          photo: Nova5Photo,
        },
      },
      {
        sprite_url: supernova6,
        position: {
          x: 50,
          y: 50,
        },
        size: 512,
        scale: !isMobile ? 128 : 10,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 2, y: 1 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641632558894022657",
          usage: "51",
          name: "Supernova #6",
          supernovaId: "SCA82DA53B",
          status: "active",
          location: "California State University Fullerton",
          venue: "Titan Shops Floor 1",
          installed: "January 2020",
          country: "USA / Fullerton",
          price: "0.04",
          manufactured: "December 2019",
          installed: "January 2020",
          photo: Nova6Photo,
        },
      },
      {
        sprite_url: supernova7,
        position: {
          x: 20,
          y: 38,
        },
        size: 512,
        scale: !isMobile ? 128 : 8,
        scale_sprite: !isMobile ? 128 : 8,
        parallax: { x: 1, y: 1 },
        supernovaDetails: {
          opeanSeaLink:
            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/47083000863490529531029625056415594595157165207973535493995641633658405650433",
          usage: "43",
          name: "Supernova #7",
          supernovaId: "SC668D8480",
          status: "active",
          location: "California State University Los Angeles",
          venue: "Salazar Hall Cafe",
          installed: "January 2020",
          country: "USA / Los Angeles",
          price: "0.05",
          manufactured: "December 2019",
          installed: "January 2020",
          photo: Nova7Photo,
        },
      },
    ],
  },
];
