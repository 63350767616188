import React, { useMemo, useRef, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

//Import Component
import {
  ControllButton,
  ControllWrapper,
  ControllButtonTitle,
  SearchForm,
  SearchInputWrapper,
  SearchInput,
  SearchResultWrapper,
  SearchResult,
  ControllButtonInput,
  AnimationWrapper,
} from "./ControllPanelStyled";

//Import Actions
import {
  TransitionNavigation,
  SelectSector,
  SetSuperNovaDetails,
  SetSelectedSector
} from "../../redux/actions";

//Import nebulas
import { ACTIVE_SUPERNOVAS } from "../../api/NebulaServices/config"

//Import congig
import { SectorsConfig } from "../../components/screens/second/config"

export const ControllPanel = ({ onChartOpen, onSearchOpen }) => {
  const [isSearchState, setSearhState] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef(null);
  const dispatch = useDispatch();

  const handleSearchOpen = () => {
    onSearchOpen();
    setSearhState(true);
  };

  //Search form start

  const searchNFT = useMemo(() => {
    const keys = ['id', 'name'];
    if (searchValue !== '') {
      return [...ACTIVE_SUPERNOVAS]?.filter(nft => keys.some(key => nft[key].toLowerCase().includes(searchValue.toLowerCase())))
    }
    return [];
  }, [searchValue])

  const handleClick = ({ zoom, sprite_url, supernova_detail, sector_id }) => {
    dispatch(TransitionNavigation(zoom, sprite_url));
    dispatch(SelectSector({ isVisible: false }));
    dispatch(SetSelectedSector({ sector_id: sector_id }));
    dispatch(SetSuperNovaDetails({ supernova_detail }));
  };

  const findNft = (id = null, config = []) => {
    let sectorId,
      spriteUrl,
      infoNFT;
    config.map((item, index) => {
      item.nftList.map(list => {
        if (list.supernovaDetails.supernovaId === id) {
          infoNFT = list.supernovaDetails;
          sectorId = index + 1;
          spriteUrl = list.sprite_url
          return
        }
      })
    })
    return {
      sprite_url: spriteUrl,
      sector_id: sectorId,
      nft_info: infoNFT
    }
  }

  // const keyPressSearch = (e) => {
  //   console.log(e.code)
  // }
  // window.addEventListener('keydown', keyPressSearch)
  //Search form end

  const handleCloseSearch = () => setSearhState(false);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      handleCloseSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <ControllWrapper>
      {!isSearchState ? (
        <AnimationWrapper positionAnimation="left">
          <ControllButton onClick={onChartOpen}>
            <ControllButtonTitle>Charts</ControllButtonTitle>
          </ControllButton>
          <ControllButton onClick={handleSearchOpen}>
            <ControllButtonTitle>Search star</ControllButtonTitle>
          </ControllButton>
        </AnimationWrapper>
      ) : (
        <AnimationWrapper positionAnimation="right" ref={searchRef}>
          <SearchForm>
            <SearchInputWrapper>
              <SearchInput
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                onFocus={() => { console.log('enter') }}
              />
              <SearchResultWrapper>
                {searchNFT.map((item, index) => {
                  return (
                    <SearchResult
                      key={index}

                      onClick={() => {
                        handleClick({
                          zoom: 3,
                          sprite_url: findNft(item.id, SectorsConfig)?.sprite_url,
                          supernova_detail: findNft(item.id, SectorsConfig)?.nft_info,
                          sector_id: findNft(item.id, SectorsConfig)?.sector_id
                        })
                      }}
                    >
                      {item.name}
                    </SearchResult>
                  )
                })
                }
              </SearchResultWrapper>
            </SearchInputWrapper>
            <ControllButtonInput>Engage</ControllButtonInput>
          </SearchForm>
        </AnimationWrapper>
      )}
    </ControllWrapper>
  );
};
