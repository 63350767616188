import React from "react";
import {
  NavigationBottom,
  NavigationBottomWrappaer,
  NovaName,
} from "./NavigationBottomStyled";
import { useSelector } from "react-redux";

//Import component
import { StarsOnlineComponent } from "../StartsOnline";
import { ControllPanel } from "../ControllPanel";
import { NavigationSlider } from "../NavigationSlider";

export const NavigationBottomComponent = ({ onChartOpen, onSearchOpen }) => {
  const currentZoom = useSelector((state) => state?.env.zoom);
  const novaName = useSelector((state) => state?.nft?.superNovaDetails?.name);
  return (
    <NavigationBottomWrappaer>
      <NavigationBottom />
      <NavigationSlider />
      {currentZoom === 3 && <NovaName>{novaName || ""}</NovaName>}
      <StarsOnlineComponent />
      {currentZoom !== 3 && (
        <ControllPanel
          onChartOpen={onChartOpen}
          onSearchOpen={onSearchOpen}
          hiden={true}
        />
      )}
    </NavigationBottomWrappaer>
  );
};
