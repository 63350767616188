import React from "react";
import { useSelector } from "react-redux";

//Import styles
import {
  StarsOnline,
  StarsOnlineWrapper,
  StarsDescr,
} from "./SratsOnlineStyled";

export const StarsOnlineComponent = () => {
  const SupernovasOnlyne = useSelector((state) => state.nft?.supernovasOnline);
  const SuperNoveUsage = useSelector((state) => state.nft?.superNovaDetails?.usage);
  const stateZoom = useSelector((state) => state?.env?.zoom);

  return (
    <StarsOnlineWrapper>
      <StarsOnline>{stateZoom === 3 ? SuperNoveUsage : SupernovasOnlyne}</StarsOnline>
      <StarsDescr>{stateZoom === 3 ? 'TOTAL USAGE' : 'SUPERNOVAS ONLINE'}</StarsDescr>
    </StarsOnlineWrapper>
  );
};
