import sprite1 from "./../../../assets/sprites/1.png";
import { isMobile } from "react-device-detect";

export const SpriteConfigSecond = [
  {
    sprite_url: sprite1,
    position: {
      x: 50,
      y: 50,
    },
    size: 512,
    scale: !isMobile ? 128 : 22,
    scale_sprite: !isMobile ? 128 : 16,
    parallax: { x: 3, y: 3 },
  },
];
