import styled, { keyframes } from "styled-components";

const Swiper = (position) => keyframes`
    from { transform: translateX(${position === "left" ? "-300px" : "300px"}); 
    z-index: -1;
    opacity: 0;}
    to { transform: translateX(0px);
    z-index: 1;
    opacity: 1;}
`;

const ControllWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  z-index: 2;
`;

const ControllButton = styled.div`
  cursor: pointer;
  padding: 7px 5px;
  border-radius: 5px;
  border: 1px solid #FFDD5083;
  position: relative;
  width: auto;
  min-width: 110px;
  text-align: center;
  box-sizing: border-box;
  transition: 0.3s ease;
  &:not(:last-child){
    margin-right: 10px;
  }
  &:hover{
    border-color: #FFDD50;
  }
`;

const ControllButtonTitle = styled.span`
  font-size: 14px;
  color: #fff;
  font-family: Montserrat;
`;

const SearchForm = styled.div`
  display: flex;
  align-items: flex-start;
`

const SearchInputWrapper = styled.div`
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0 5px;
  box-sizing: border-box;
`
const SearchInput = styled.input`
  display: block;  
  position: relative;
  padding: 10px 0;
  background: transparent;
  outline: none;
  margin: 0;
  font-size: 14px;
  line-height: 1.285em;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.28px;
  border: none;
`;

const SearchResultWrapper = styled.div`
  max-height: 113px;
  overflow-Y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
const SearchResult = styled.div`
  font-size: 14px;
  line-height: 1.285em;
  color: #fff;
  font-weight: 500;
  padding: 5px 0;
  letter-spacing: -0.28px;
  cursor: pointer;
`

const ControllButtonInput = styled.div`
  cursor: pointer;
  background: #ffdd50;
  padding: 10.5px 1px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  width: auto;
  min-width: 160px;
  text-align: center;
`;

const AnimationWrapper = styled.div`
  display: flex;
  animation: ${(props) => Swiper(props.positionAnimation)} 0.4s linear;
  animation-delay: ${(props) => props.delay};
`;

const CloseSearchIcon = styled.img`
  width: 35px;
  height: 35px;
  position: relative;
  cursor: pointer;
`

export {
  ControllWrapper,
  ControllButton,
  ControllButtonTitle,
  SearchForm,
  SearchInputWrapper,
  SearchInput,
  SearchResultWrapper,
  SearchResult,
  ControllButtonInput,
  AnimationWrapper,
  CloseSearchIcon
};
