import React, { useEffect, useState } from "react";
import Loader from "react-spinners/CircleLoader";

import CLOSE_ICON from "../../assets/close.png";
import TELEMETRY_SVG from "../../assets/supernova-machine.svg";

//Import styles component
import {
  ChartModalWrapper,
  ModalTitle,
  Divider,
  CloseIcon,
  IDMachine,
  BottomWrapper,
  BottomLabel,
} from "./MachineTelemetryStyled";
import { TelemetrySVG } from "./component";

export const MachineTelemetry = ({ onClose, novaId }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(async () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <ChartModalWrapper>
      <CloseIcon onClick={onClose} src={CLOSE_ICON} />
      <ModalTitle>Machine Telemetry</ModalTitle>
      <Divider />
      <IDMachine>ID: {novaId || ""}</IDMachine>
      <div style={{ marginTop: 50 }} />
      {isLoading ? (
        <Loader color={"#FFDD50"} loading={true} css={{}} size={150} />
      ) : (
        <TelemetrySVG svgPath={TELEMETRY_SVG} />
      )}
      <BottomWrapper>
        <BottomLabel>Status : Active</BottomLabel>
        <BottomLabel>Connection : Online</BottomLabel>
        <BottomLabel>Cellular signal : Good</BottomLabel>
      </BottomWrapper>
    </ChartModalWrapper>
  );
};
