import nebula1 from "../../../assets/zoom_first/layer-1.png";
import nebula2 from "../../../assets/zoom_first/layer-2.png";
import nebula3 from "../../../assets/zoom_first/layer-3.png";
import nebula4 from "../../../assets/zoom_first/layer-4.png";
import nebula5 from "../../../assets/zoom_first/layer-5.png";
import nebula6 from "../../../assets/zoom_first/layer-6.png";

export const NebulaConfig = [
  {
    img: nebula1,
    parallax: { x: 2, y: 1 },
    zIndex: 4,
  },
  {
    img: nebula2,
    parallax: { x: 2, y: 1 },
    zIndex: 5,
  },
  {
    img: nebula3,
    parallax: { x: 2, y: 1 },
    zIndex: 8,
  },
  {
    img: nebula4,
    parallax: { x: 3, y: 2 },
    zIndex: 6,
  },
  {
    img: nebula5,
    parallax: { x: 3, y: 2 },
    zIndex: 7,
  },
  {
    img: nebula6,
    parallax: { x: 3, y: 2 },
    zIndex: 9,
  },
];
