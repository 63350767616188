export const ServiceCall = async ({
  route,
  type = "GET",
  params,
  token = null,
}) => {
  const myHeaders = new Headers({
    "Content-Type": "application/json",
  });

  token && myHeaders.append("Authorization", `Bearer ${token}`);
  const requestParams = {
    method: type,
    headers: myHeaders,
  };

  if (type !== "GET") {
    Object.assign(requestParams, { body: JSON.stringify(params) });
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${route}`,
    requestParams
  );

  const parseResponse = await response.json();

  return Object.assign(parseResponse, {
    statusCode: response.status,
  });
};
