import styled, { keyframes } from "styled-components";

export const SlideUp = keyframes`
  0% { 
    transform: translate(-50%, 70%); 
    z-index: 0
  }
  100% { 
    transform: translate(-50%, -50%);
  }
`;

const TestImage = styled.img`
  height: 100%;
`;

const ChartModalWrapper = styled.div`
  position: absolute;
  max-width: 700px;
  height: 544px;
  width: 700px;
  background: rgba(0,0,0,0.352941);
  border: 1px solid #464646;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 43px 27px 10px 27px;
  top: 50%;
  left: 50%;
  z-index: 66;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: ${SlideUp} 0.6s linear;
  animation-delay: ${(props) => props.delay};
`;

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0,0,0,0.352941) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  cursor: pointer;
`;

export { ChartModalWrapper, CloseIcon, TestImage };
