import styled, { keyframes } from "styled-components";
import { SPRITES_CONFIG } from "../../../config";

export const vw = (value) => value / 19.2 + "vw";

const playX = (size) => keyframes`
    from {background-position-x: 0;}
    to {background-position-x: -${size * 8}px;}
`;

const playY = (size) => keyframes`
  from {background-position-y: 0;}
  to {background-position-y: -${size * 8}px;}
`;

export const AnimatedSpriteWrapper = styled.div`
  position: absolute;
  width: ${(props) => (props.scale * SPRITES_CONFIG.spriteW) / 8}px;
  height: ${(props) => (props.scale * SPRITES_CONFIG.spriteW) / 8}px;
  left: ${(props) => props.position.x}%;
  top: ${(props) => props.position.y}%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 3;
`;

export const AnimatedSpriteStyled = styled.div`
  background-image: url(${(props) => props.spriteSheet});
  width: ${(props) => (props.scale * SPRITES_CONFIG.spriteW) / 8}px;
  height: ${(props) => (props.scale * SPRITES_CONFIG.spriteW) / 8}px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: ${(props) => playX(props.size)} 1s steps(8) infinite,
    ${(props) => playY(props.size)} 8s steps(8) infinite;
  @media (max-width: 992px) {
    width: ${(props) => props.size / 4}px;
    height: ${(props) => props.size / 4}px;
    background-size ${(props) => (props.size / 4) * 8}px;
    animation: ${(props) => playX(props.size / 4)} 1s steps(8) infinite,
    ${(props) => playY(props.size / 4)} 8s steps(8) infinite;
  }
`;

export const SpriteControl = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  z-index: 2;
  width: ${(props) => (props.scale * SPRITES_CONFIG.spriteW) / 8}px;
  height: ${(props) => (props.scale * SPRITES_CONFIG.spriteW) / 8}px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

export const UseStartNotification = styled.span`
  color: yellow;
  position: absolute;
  left: ${(props) => props.position.x + 35}%;
  top: ${(props) => props.position.y + 50}%;
  width: 100px;
`;
