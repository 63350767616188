import styled from "styled-components";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`;

const ScrolbleWrapper = styled.div`
height: 150px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TableItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: ${(props) => (props?.thead ? "1px solid rgba( 255 , 255 , 255 , 0.502 )" : "none")};
`;

const Count = styled.div`
  width: 8%;
`;

const Name = styled.div`
  width: 42%;
`;

const MachinesInfo = styled.div`
  width: 16.6%;
  display: flex;
  justify-content: center
`;

const TableDivider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba( 255 , 255 , 255 , 0.502 );
`;

const TableLabel = styled.span`
  font-size: ${(props) => (props?.bold ? "12px" : "15px")};
  color: #fff;
  font-weight: ${(props) => (props?.bold ? 600 : 400)};
  text-transform: ${(props) => (props?.bold ? "uppercase" : "capitalize")};
`;

export {
  TableWrapper,
  TableItem,
  Count,
  Name,
  MachinesInfo,
  TableLabel,
  TableDivider,
  ScrolbleWrapper,
};
