import React, { useCallback, useMemo, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

//Import Component
import {
  ScreenFirst,
  ScreenSecond,
  Star,
  ScreenThird,
} from "./components/screens";
import { AppStyled, Button } from "./AppStyled";
import { Transition } from "./components/Transition";
import { StarsWrapper } from "./components/screens/ZoomStyled";
import { StarsConfig } from "./components/screens/star/config";
import { TwinklingStars } from "./components/screens/TwinklingStars";
import { NavigationProvider } from "./components/NavigationProvider";
import "./assets/fonts/montserrat/stylesheet.css";
import "./assets/fonts/brevier/stylesheet.css";

//Import Services
import { GetNebulaStatistics } from "./api/NebulaServices";

//Import Actions
import { TransitionNavigation, SetOnlineSupernovas } from "./redux/actions";

export const App = () => {
  const CurrentZoom = useSelector((state) => state.env.zoom);
  const IsTransition = useSelector((state) => state.env.transition);
  const dispatch = useDispatch();
  const [firstView, setFirstView] = useState(false);

  useEffect(async () => {
    const active_supernovas = await GetNebulaStatistics();
    if (active_supernovas !== null) {
      dispatch(SetOnlineSupernovas({ supernovas: active_supernovas }));
    }
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    dispatch(TransitionNavigation(1));
    const transitionEffect = setTimeout(() => {
      setFirstView(true);
    }, 3000);
    return () => {
      window.clearTimeout(transitionEffect);
      abortController.abort();
    };
  }, [])

  const parallaxMove = (event, ref, x, y, parallax, size) => {
    const mouseX = event.pageX;
    const mouseY = event.pageY;
    if (ref.current) {
      ref.current.style.transform = `translateX(${Math.round(
        (window.innerWidth - mouseX * parallax.x) / 90 - size / 2
      )}px) translateY(${Math.round(
        (window.innerHeight - mouseY * parallax.y) / 90 - size / 2
      )}px)`;
    }
  };

  const setParallax = useCallback((ref, x, y, parallax, size) => {
    if (!isMobile) {
      if (window.innerWidth >= 768) {
        window.addEventListener("mousemove", (e) =>
          parallaxMove(e, ref, x, y, parallax, size)
        );
      }
    }
  }, []);

  const renderScene = useMemo(() => {
    return CurrentZoom === 1 ? (
      <ScreenFirst setParallax={setParallax} />
    ) : CurrentZoom === 2 ? (
      <ScreenSecond setParallax={setParallax} />
    ) : (
      <ScreenThird setParallax={setParallax} />
    );
  }, [CurrentZoom]);

  const starsList = useMemo(
    () =>
      StarsConfig.map((star, index) => (
        <Star key={star.zIndex + index} star={star} setParallax={setParallax} />
      )),
    [StarsConfig]
  );

  return (
     <AppStyled>
      <Transition active={IsTransition} />
      {firstView && (
        <NavigationProvider>
          <StarsWrapper>{starsList}</StarsWrapper>
          {(!isMobile || CurrentZoom === 3) && (
            <TwinklingStars quantity={!isMobile ? 100 : 30} />
          )}
          {renderScene}
        </NavigationProvider>
      )}
    </AppStyled>
  );
};
