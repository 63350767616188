import React, { useEffect, useState } from "react";

import CLOSE_ICON from "../../assets/close.png";

//Import styles component
import {
  ChartModalWrapper,
  ModalTitle,
  Divider,
  CloseIcon,
  IDMachine,
  SingleTimelineWrapper,
  DecorCirlce,
  TimelineDecor,
  LabelWrapper,
  LabelTitle,
  LabelDescr,
  LabelDivider,
} from "./MachineLogStyled";
import { TimeLineCircle } from "./component/TimeLineCircle";

export const MachineLog = ({ onClose, manufcture, installed, novaId }) => {
  return (
    <ChartModalWrapper>
      <CloseIcon onClick={onClose} src={CLOSE_ICON} />
      <ModalTitle>Machine Log</ModalTitle>
      <Divider />
      <IDMachine>ID: {novaId || ""}</IDMachine>
      <div style={{ marginTop: 50 }} />

      <SingleTimelineWrapper>
        <TimelineDecor>
          <DecorCirlce />
          <TimeLineCircle />
        </TimelineDecor>
        <LabelWrapper>
          <LabelTitle>Manufactured</LabelTitle>
          <LabelDescr>
            <LabelDivider>:</LabelDivider>
            {manufcture || ""}
          </LabelDescr>
        </LabelWrapper>
      </SingleTimelineWrapper>

      <SingleTimelineWrapper>
        <TimelineDecor>
          <DecorCirlce last />
          <TimeLineCircle />
        </TimelineDecor>
        <LabelWrapper>
          <LabelTitle> Installed</LabelTitle>
          <LabelDescr>
            <LabelDivider>:</LabelDivider>
            {installed || ""}
          </LabelDescr>
        </LabelWrapper>
      </SingleTimelineWrapper>
    </ChartModalWrapper>
  );
};
