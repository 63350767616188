import React, { useEffect, useRef, useState } from "react";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import Loader from "react-spinners/CircleLoader";

//Import Services
import { GetNebulaActives } from "../../api/NebulaServices";

import CLOSE_ICON from "../../assets/close.png";

//Import styles component
import {
  ChartModalWrapper,
  ModalTitle,
  Divider,
  CloseIcon,
  ScrolbleWrapper,
} from "./ChartStyled";
import { MachinesUsedTable } from "../MachinesUsed";
import { CustomTooltip } from "./CustomeToolTip";

export const ChartModal = ({ onClose, NFTSingleID }) => {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [supernovas, setSupernovas] = useState([]);
  const scrollBar = useRef();

  const scrollableBar = (el) => {
    el.addEventListener('wheel', (e) => {
      e.deltaY > 0 ? el.scrollLeft += 20 : el.scrollLeft -= 20;
    })
  }

  useEffect(() => {
    if (scrollBar?.current?.scrollWidth !== undefined) {
      scrollBar.current.scrollLeft = scrollBar.current.scrollWidth;
      scrollableBar(scrollBar.current)
    }
  });

  useEffect(async () => {
    const res = await GetNebulaActives(NFTSingleID);
    if (res !== null) {
      res.array_to_put_data.length > 0 && setChartData(res.array_to_put_data);
      res.supernovas.length > 0 && setSupernovas(res.supernovas);
      setLoading(false);

    }
  }, []);

  return (
    <ChartModalWrapper>
      <CloseIcon onClick={onClose} src={CLOSE_ICON} />
      <ModalTitle>Usage week over week</ModalTitle>
      <Divider />
      <div style={{ marginTop: 50 }} />
      {isLoading ? (
        <Loader color={"#FFDD50"} loading={true} css={{}} size={150} />
      ) : (
        <ScrolbleWrapper ref={scrollBar}>
          <ResponsiveContainer width={2000} height={140}>
            <AreaChart data={chartData}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FF4E7B" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FF527E26" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomTooltip />} />
              <Area
                dataKey="sessions"
                stroke="#FF4E7B"
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </ScrolbleWrapper>
      )}
      {
        NFTSingleID === undefined ?
          <>
            -<ModalTitle style={{ marginTop: 30 }}>Most used machines</ModalTitle>
            <Divider />
            <MachinesUsedTable isLoading={isLoading} supernovas={supernovas} />
          </> :
          ''
      }

    </ChartModalWrapper >
  );
};
