import React from "react";

import { ConnectToMMWrapper, MMUrl } from "./ConnectToMMStyle";

export const ConnectToMM = ({ children, onClick, isConnected }) => {
  return (
    <ConnectToMMWrapper isConnected={isConnected} onClick={onClick}>
      <MMUrl>{children}</MMUrl>
    </ConnectToMMWrapper>
  );
};
