import { EnvTypes } from "../../types";

const ChangeZoom = ({ zoom = 1 }) => {
  return {
    type: EnvTypes.SET_ZOOM,
    payload: zoom,
  };
};

const SetTransitionVisible = (dispatch) => {
  dispatch({
    type: EnvTypes.SET_TRANSITION_NAVIGATION,
    payload: true,
  });
};

const TransitionNavigation =
  (zoom, selectedNFT = null) =>
  async (dispatch) => {
    //Turn on transition
    SetTransitionVisible(dispatch);

    //Turn of transition delay 3s
    setTimeout(() => {
      dispatch({
        type: EnvTypes.SET_ZOOM,
        payload: { zoom, selectedNFT },
      });
      dispatch({
        type: EnvTypes.SET_TRANSITION_NAVIGATION,
        payload: false,
      });
    }, 3000);
  };

const SelectSector = ({ isVisible = false }) => {
  return {
    type: EnvTypes.SET_VISIBLE_SECTION,
    payload: isVisible,
  };
};

export { ChangeZoom, TransitionNavigation, SelectSector };
